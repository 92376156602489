import './styles/app.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Import Pages
import Home from './pages/Home';
/* import About from './pages/About';
import Portfolio from './pages/Portfolio'; */
import Sidebar from './components/Sidebar';
import Header from './components/Header';

function App() {
  return (
    <Router>
       <header className="is-hidden-tablet header">
          <Header/>
        </header>
      <main className="main container">
        <aside className="sidebar has-background-ligh is-hidden-mobile">
          <Sidebar/>
        </aside>
        <article className="mainContent">
          <Switch>
            {/* <Route path="/about">
              <About/>
            </Route>
            <Route path="/portfolio">
              <Portfolio/>
            </Route> */}
            <Route path="/">
              <Home/>
            </Route>    
          </Switch>
        </article>
        
      </main>
      
    </Router>  
  );
}


export default App;
 