import React from 'react';
//images
import cvphoto from '../img/cvphoto.jpg';
//styles
import '../styles/NameSection.scss';


const NameSection = () => {
    return(
        <section className="hero is-dark is-bold">
            <div className="hero-body">
                <div className="container nameSection">
                    <div className="photo">
                        <figure className="image is-128x128">
                            <img src={cvphoto} className="is-rounded" alt="cvphoto"/>
                        </figure>
                        <div className="content is-medium">    
                            <h1 className="title">Vitaliy Syveniuk</h1>
                            <h2 className="subtitle">Node.js software engineer</h2>
                        </div>
                    </div>
                    <div className="contacts">
                        <ul>
                            <li>
                                <span><i className="fas fa-mobile-alt"></i></span><a href="tel:0934450318"className="ml-2">+38 (093) 445-03-18</a>
                            </li>
                            <li>
                                <span><i className="far fa-envelope"></i></span><a href="mailto:vsyveniu@gmail.com" className="ml-2">vsyveniu@gmail.com</a>
                            </li>
                            <li>
                                <span><i className="far fa-paper-plane"></i></span><a className="ml-2" href="https://t.me/vsyveniu">@vsyveniu</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default NameSection;