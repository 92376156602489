import React from "react";
//import images
import chesm from "../img/chesm.png";
import specter from "../img/specterdent.png";
import fairsite from "../img/fairsite.png";
import howtogrow from "../img/howtogrow.png";
import amsaan from "../img/amsaan.png";
//import elements
import Tile from "./elements/Tile";
//import styles
import "../styles/sidebar.scss";

const WorkSection = () => {
  return (
    <section className="" id="portfolio">
      <div className="content is-medium mt-6 ml-6 mr-6">
        <div className="is-divider" data-content="a few examples"></div>
        <h2 className="title">Work examples</h2>
        {/* <div className="tile is-ancestor">
          <div className="tile is-vertical is-parent">
            <div className="tile is-child box">
              <Tile
                alt="chesm"
                title="Amsaan"
                text="Back-end of a mobile app"
                src={amsaan}
                href="https://play.google.com/store/apps/details?id=com.gpdit.amsaan&hl=uk&gl=US"
              />
            </div>
          </div>
        </div> */}
        <div className="tile is-ancestor">
          <div className="tile is-4 is-vertical is-parent">
            <div className="tile is-child box">
              <Tile alt="chesm" title="Chesmo" text="Back-end / Front-end" src={chesm} href="https://4ecm.com.ua/" />
            </div>
            <div className="tile is-child box">
              <Tile
                alt="specter"
                title="SpecterDent"
                text="Back-end / Partially front-end"
                src={specter}
                href="https://specterdent.com.ua/uk/"
              />
            </div>
          </div>
          <div className="tile is-parent">
            <div className="tile is-child box">
              <Tile
                alt="fairsite"
                title="FAIR Agency website"
                text="Front-end"
                src={fairsite}
                href="https://fair-agency.com.ua/"
              />
            </div>
          </div>
        </div>
        <div className="tile is-ancestor">
          <div className="tile is-vertical is-parent">
            <div className="tile is-child box">
              <Tile alt="chesm" title="Howtogrow" text="Back-end" src={howtogrow} href="https://howtogrow.com.ua/" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkSection;
