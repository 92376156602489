import React, { useState } from 'react';
import styled from 'styled-components';

const Icon = styled.div`
    width: 35px;
    height: 35px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${props => props.isHeader ? 0 : "20px"};
    text-align:center;
    border-bottom: ${props => props.isActive ? "1px solid #3298DC" : "none"};
    margin-left: ${props => props.isHeader ? "15px" : 0};
`;

const LinkIconHover = ({img, text, alt, isHeader}) => {
    const [isActive, setActive] = useState("true");

    const toggleIcon = () => {
        setActive(!isActive);
    }

    return(
        <Icon onMouseEnter={toggleIcon} onMouseLeave={toggleIcon} isActive={!isActive} isHeader={isHeader}>
            <img className={`image sidebar-image ${!isActive ? "is-hidden" : ""}`} src={img} 
            alt={alt}/>
            <p className={`is-size-7 ${isActive ? "is-hidden" : null}`}>{text}</p>
        </Icon>
    );
};

export default LinkIconHover;