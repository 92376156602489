import React from "react";
//elements
import DevIcon from "./elements/DevIcon";
//styles
import "../styles/SkillSection.scss";
//svg
import { ReactComponent as ReactLogo } from "../img/react.svg";
import { ReactComponent as PHPLogo } from "../img/php.svg";
import { ReactComponent as JsLogo } from "../img/javascript.svg";
//import {ReactComponent as MysqlLogo} from '../img/mysql.svg';
import { ReactComponent as CssLogo } from "../img/css.svg";
import { ReactComponent as HtmlLogo } from "../img/html.svg";
import { ReactComponent as VueLogo } from "../img/vuejs.svg";
import { ReactComponent as LaraLogo } from "../img/laravel.svg";
//import {ReactComponent as WpLogo} from '../img/wordpress.svg';
import { ReactComponent as CLogo } from "../img/c.svg";
import { ReactComponent as SassLogo } from "../img/sass.svg";
import { ReactComponent as GulpLogo } from "../img/gulp.svg";
import { ReactComponent as SqlLogo } from "../img/sql.svg";
//import {ReactComponent as ApacheLogo} from '../img/apache.svg';
import { ReactComponent as GitLogo } from "../img/git.svg";
import { ReactComponent as NodeLogo } from "../img/node.svg";
import { ReactComponent as ChipLogo } from "../img/chip.svg";
import { ReactComponent as ExpressLogo } from "../img/express.svg";
import { ReactComponent as NestLogo } from "../img/nestjs-plain.svg";
import { ReactComponent as DockerLogo } from "../img/docker-plain.svg";

const SkillSection = () => {
  return (
    <section className="container" id="about">
      <div className="content is-medium mt-6 ml-6 mr-6">
        <h2 className="title">Who is this guy?</h2>
        <p className="block">
          Hi, I am a software engineer interested in web development and also in internet of things. I am passioned to
          combine web development skills and microcontrollers firmware programming. I believe that smart device must
          also have convenient and attractive interface to deliver processed data to a user or provide a remote control
          of itself. As a skilled Node.js developer, I have a strong understanding of server-side programming and experience
           with building scalable web applications. Additionally, 
           I have knowledge of ESP32 microcontrollers, enabling me to design and develop embedded systems that can 
           communicate with web-based applications. My expertise in both Node.js and embedded systems 
           allows me to create innovative and efficient solutions for a variety of projects.
        </p>
      </div>
      <div className="content is-medium mt-6 ml-6 mr-6">
        <div className="is-divider" data-content="what am i using"></div>
        <h2 className="title">Skills and tools</h2>
        <div className="block skillrow">
          <div className="svgbox">
            <DevIcon size="70" color="is-info" text="" srcComponent={<JsLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="65" color="is-info" text="" srcComponent={<NestLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="50" color="is-info" text="" srcComponent={<VueLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="50" color="is-info" text="" srcComponent={<ExpressLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="50" color="is-info" text="" srcComponent={<SqlLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="45" color="is-info" text="" srcComponent={<LaraLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="50" color="is-info" text="" srcComponent={<PHPLogo />} />
          </div>

          <div className="svgbox">
            <DevIcon size="50" color="is-info" text="" srcComponent={<CssLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="50" color="is-info" text="" srcComponent={<HtmlLogo />} />
          </div>

          <div className="svgbox">
            <DevIcon size="40" color="is-info" text="" srcComponent={<CLogo />} />
          </div>
          {/* <div className="svgbox">
            <DevIcon
              size="40"
              color="is-info"
              text="Used sometimes / not sure if i need it anymore due to webpack"
              srcComponent={<GulpLogo />}
            />
          </div> */}
          <div className="svgbox">
            <DevIcon size="40" color="is-info" text="" srcComponent={<GitLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="50" color="is-info" text="" srcComponent={<DockerLogo />} />
          </div>
          <div className="svgbox">
            <DevIcon size="40" color="is-info" text="ESP-IDF / esp32" srcComponent={<ChipLogo />} />
          </div>
        </div>
      </div>
      {/* <div className="content is-medium mt-6 ml-6 mr-6">
                <h2 className="title">Next steps</h2>
                <div className="block skillrow">
                    <div className="svgbox">
                        <DevIcon size="15" color="is-warning" text="Beginner / studying" srcComponent={<ReactLogo/>} />
                    </div>
                    <div className="svgbox">
                        <DevIcon size="15" color="is-warning" text="Beginner / studying" srcComponent={<NodeLogo/>} />
                    </div>
                    <div className="svgbox">
                        <DevIcon size="0" color="is-info" text="stm 32" srcComponent={<ChipLogo/>} />
                    </div>
                    <div className="svgbox">
                        <DevIcon size="0" color="is-info" text="Beginner" srcComponent={<ExpressLogo/>} />
                    </div>
                </div>
            </div> */}
    </section>
  );
};

export default SkillSection;
