import React from 'react';
//import { Link } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";
//import images
import home from '../img/home.svg';
import about from '../img/man.svg';
import gears from '../img/gears.svg';
import facebook from '../img/facebook.svg';
import github from '../img/github.svg';
import linkedin from '../img/linkedin.svg';
//import elements
import LinkIconHover from './elements/LinkIconHover';
//import styles
import '../styles/header.scss';

const scrollToTop = () => {
  scroll.scrollToTop();
};

const Header = () => {
    return(
      <section className="iconsWrapper has-background-light">
          <nav>
              <ul>
                <li>
                  <Link to="" onClick={scrollToTop}>
                      <LinkIconHover img={home} text={"Home"} alt={"home"} isHeader/>
                  </Link>
                </li>
                <li>
                  <Link to="about" smooth={true}>
                      <LinkIconHover img={about} text={"About"} alt={"about"} isHeader/>
                  </Link>
                </li>
                <li>
                  <Link to="portfolio" smooth={true}>
                      <LinkIconHover img={gears} text={"Work"} alt={"work"} isHeader/>
                  </Link>
                </li>
              </ul>
          </nav>
          <div className="social">
            <a href="https://github.com/vsyveniu" target="_blank" rel="noreferrer">
                <LinkIconHover img={github} text={"Github"} alt={"github"} isHeader/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100013359376604" target="_blank" rel="noreferrer">
                <LinkIconHover img={facebook} text={"Facebook"} alt={"facebook"} isHeader/>
            </a>
            <a href="https://www.linkedin.com/in/vitaliy-siveniuk-b15076160/" target="_blank" rel="noreferrer">
                <LinkIconHover img={linkedin} text={"LinkedIn"} alt={"facebook"} isHeader/>
            </a>
          </div>
        </section>
    )
}

export default Header;