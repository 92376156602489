import React, { useState } from 'react';
import styled from 'styled-components';

const Tile = styled.a`
    cursor: pointer;
`;

const DevIcon = ({alt, title, text, src, href}) => {
    const [isActive, setActive] = useState("true");

    const toggleIcon = () => {
        setActive(!isActive);
    }

    return( 
        <Tile onMouseEnter={toggleIcon} onMouseLeave={toggleIcon} isActive={!isActive} href={href} target="_blank">
            <div className="">
            <p className="content is-medium">{title}</p>
            <div>
                <img src={src} alt={alt}/>
            </div>
            <p className="content is-small">{text}</p>
            </div>
        </Tile>
    );
};

export default DevIcon;