import React from "react";
//images
import ucode from "../img/ucode.jpeg";
import unit from "../img/unit.png";
import fair from "../img/FAIR.png";
import nudpsu from "../img/nudpsu.png";
import tdl from "../img/tdl.svg";
//styles
import "../styles/TimelineSection.scss";

const TimelineSection = () => {
  return (
    <section className="timeline-block">
      <div className="content is-medium mt-6 ml-6 mr-6">
        <div className="is-divider" data-content="what i was up for past years"></div>
        <h2 className="title">Education and experience</h2>
      </div>
      <div className="timeline is-centered">
        {/* <header className="timeline-header">
          <span className="tag is-medium is-info">2022</span>
        </header> */}
        <div className="timeline-item is-info">
          <div className="timeline-marker is-icon">
            <i className="fa fa-free"></i>
          </div>
          <div className="timeline-content">
            {/* <p className="heading">April 2020</p> */}
            <p>Qualium systems</p>
          </div>
        </div>
        <div className="timeline-item is-info">
          <div className="timeline-marker is-icon">
            <i className="fa fa-free"></i>
          </div>
          <div className="timeline-content">
            {/* <p className="heading">April 2020</p> */}
            <p>freelance</p>
          </div>
        </div>
        {/* <header className="timeline-header">
          <span className="tag is-medium is-info">2021</span>
        </header> */}
        <div className="timeline-item is-info">
          <div className="timeline-marker is-image is-32x32 tdl_crutch">
            <img src={tdl} width={25} height={32} alt="tdl" />
          </div>
          <div className="timeline-content">
            {/* <p className="heading">December 2020</p> */}
            <p>TDL</p>
          </div>
        </div>
        <div className="timeline-item is-info">
          <div className="timeline-marker is-icon">
            <i className="fa fa-free"></i>
          </div>
          <div className="timeline-content">
            {/* <p className="heading">April 2020</p> */}
            <p>freelance</p>
          </div>
        </div>
        {/* <div className="timeline-item is-info">
          <div className="timeline-marker is-icon">
            <i className="fa fa-flag"></i>
          </div>
          <div className="timeline-content">
             <p className="heading">December 2020</p> 
            <p>finished IoT program</p>
          </div>
        </div> */}

        <div className="timeline-item is-info">
          <div className="timeline-marker is-image is-32x32">
            <img src={ucode}  alt="ucode" />
          </div>
          <div className="timeline-content">
            {/* <p className="heading">August 2020</p> */}
            <p>Ucode IT Academy IoT program</p>
          </div>
        </div>
        {/* <div className="timeline-item is-info">
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                        <p className="heading">July 2020</p>
                        <p>quit FAIR Agency</p>
                    </div>
                </div> */}
  
        {/* <div className="timeline-item is-info">
          <div className="timeline-marker is-icon">
            <i className="fa fa-flag"></i>
          </div>
          <div className="timeline-content">
             <p className="heading">April 2020</p> 
            <p>finished UNIT Factory (42 school program) </p>
          </div>
        </div> */}

        {/* <header className="timeline-header">
          <span className="tag is-info">2020</span>
        </header> */}
        <div className="timeline-item is-info">
          <div className="timeline-marker is-image is-32x32">
            <img src={fair} alt="fair" />
          </div>
          <div className="timeline-content">
            {/* <p className="heading">April 2019</p> */}
            <p>FAIR Agency</p>
          </div>
        </div>
        {/* <header className="timeline-header">
          <span className="tag is-info">2019</span>
        </header> */}
        <div className="timeline-item is-info">
          <div className="timeline-marker is-image is-32x32">
            <img src={unit} alt="unit" />
          </div>
          <div className="timeline-content">
            {/* <p className="heading">November 2017</p> */}
            <p>UNIT Factory (42 school program)</p>
          </div>
        </div>
        {/* <div className="timeline-item is-info">
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                        <p>quit self-employed construction job</p>
                    </div>
                </div>
                <header className="timeline-header">
                    <span className="tag is-info">2017</span>
                </header>
                <div className="timeline-item is-info">
                    <div className="timeline-marker is-icon">
                        <i className="fa fa-flag"></i>
                    </div>
                    <div className="timeline-content">
                    <p className="heading">June 2014</p>
                    <p>finished University of State Tax Service of Ukraine</p>
                    </div>
                </div>
                <header className="timeline-header">
                    <span className="tag is-info">2014</span>
                </header>
                <div className="timeline-item is-info">
                    <div className="timeline-marker is-image is-32x32">
                    <img src={nudpsu} alt="nudpsu"/>
                    </div>
                    <div className="timeline-content">
                    <p className="heading">September 2009</p>
                    <p>University of State Tax Service of Ukraine</p>
                    </div>
                </div> */}
        {/*                 <div className="timeline-item is-info">
                <div className="timeline-marker"></div>
                    <div className="timeline-content">
                    <p>self-employed construction worker</p>
                    </div>
                </div> */}

        {/* <header className="timeline-header">
          <span className="tag is-medium is-info">2017</span>
        </header> */}
      </div>
    </section>
  );
};

export default TimelineSection;
