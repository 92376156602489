import React from 'react';
//components
import NameSection from '../components/NameSection';
import SkillSection from '../components/SkillSection';
import TimelineSection from '../components/TimelineSection';
import WorkSection from '../components/WorkSection';

const Home = () => {
    return(
        <article>
            <NameSection/>
            <SkillSection/>
            <TimelineSection/>
            <WorkSection/>
        </article>
    )
}

export default Home;