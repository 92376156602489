import React, { useState } from 'react';
import styled from 'styled-components';

const Icon = styled.div`
    width: 60px;
    height: 60px;
    cursor: pointer;
    position: relative;
    margin-left: 20px;
    margin-top: 20px;

    .devicon-modal {
       position: absolute;
       top: 50px;
       left: 20px;
       padding: 10px;
       border-radius: 5px;
       visibility: ${props => props.isActive ? "visible" : "hidden"};
       width: ${props => props.isActive ? "200px" : 0};
       -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
       -moz-box-shadow:    1px 1px 5px 0px rgba(50, 50, 50, 0.75);
       box-shadow:         1px 1px 5px 0px rgba(50, 50, 50, 0.75);
       z-index: 100;
       transition: width 0.2s;

       p{
        visibility: ${props => props.isActive ? "visible" : "hidden"};
        transition-property: visibility, max-height;
        transition-delay: 0.2s;
       }
    }

    @media (max-width: 1024px){
        .devicon-modal{
            top: 50px;
            left: 0;
        }
    }

`;

const DevIcon = ({color, size, text, srcComponent}) => {
    const [isActive, setActive] = useState("true");

    const toggleIcon = () => {
        setActive(!isActive);
    }

    return( 
        <Icon onMouseEnter={toggleIcon} onMouseLeave={toggleIcon} isActive={!isActive}>
            {srcComponent}
            <div className="devicon-modal has-background-light">
                <p className="content is-small">{!isActive ? text : ''}</p>
                <progress className={`progress ${color}`} value={size} max="100">15%</progress>
            </div>
        </Icon>
    );
};

export default DevIcon;